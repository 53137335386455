import React from 'react';
import ArrowGoTo from '../ui/ArrowGoTo';
import Card from '../ui/Card';
import Spacing from '../ui/Spacing';
import styles from './IntegrationRequestCard.module.scss';

const IntegrationRequestCard: React.SFC = () => (
  <Card hoverColor="blue">
    <a
      href="mailto:support@slab.com?subject=Slab Integration Idea"
      className={styles.container}
    >
      <Spacing all={4}>
        <Spacing bottom={5}>
          <span className={styles.prompt}>
            Not seeing what you are looking for?
          </span>
        </Spacing>
        <ArrowGoTo>Let us know</ArrowGoTo>
      </Spacing>
    </a>
  </Card>
);

export default IntegrationRequestCard;
