import { Link } from 'gatsby';
import React, { ReactNode } from 'react';
import styles from './IntegrationsFilterHeader.module.scss';

interface IntegrationsFilterLinkProps {
  children: ReactNode;
  path: string;
}

const FilterLink: React.SFC<IntegrationsFilterLinkProps> = ({
  path,
  children,
}) => (
  <Link
    to={`/integrations${path}`}
    className={styles.link}
    activeClassName={styles.active}
  >
    {children}
  </Link>
);

const IntegrationsFilterHeader: React.SFC = () => (
  <div className={styles.container}>
    <FilterLink path="/">All</FilterLink>
    <FilterLink path="/standard/">Standard</FilterLink>
    <FilterLink path="/premium/">Premium</FilterLink>
  </div>
);

export default IntegrationsFilterHeader;
