import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import Card from '../ui/Card';
import Spacing from '../ui/Spacing';
import Text from '../ui/Text';
import styles from './IntegrationCard.module.scss';

interface IntegrationCardProps {
  slug: string;
  name: string;
  logo: string;
  premium?: boolean;
  coming?: boolean;
}

const IntegrationCard = ({
  slug,
  name,
  logo,
  premium,
  coming,
}: IntegrationCardProps) => {
  const card = (
    <Card hoverColor="blue">
      <div className={styles.container}>
        <Spacing all={2}>
          <div className={styles.titleRow}>
            <div>
              <Text bold darker>
                {name}
              </Text>
            </div>
            {premium && (
              <div className={styles.tagContainer}>
                <span className={`${styles.tag} ${styles.premium}`}>
                  premium
                </span>
              </div>
            )}
            {coming && (
              <div className={styles.tagContainer}>
                <span className={`${styles.tag} ${styles.coming}`}>
                  coming soon
                </span>
              </div>
            )}
          </div>
          <div className={styles.logoContainer}>
            <div
              style={{ backgroundImage: `url(${logo})` }}
              className={classNames(styles.logo, { [styles.coming]: coming })}
            />
          </div>
        </Spacing>
      </div>
    </Card>
  );

  return <Link to={`/integrations/${slug}/`}>{card}</Link>;
};

export default IntegrationCard;
