import React, { ReactNode } from 'react';
import { IntegrationService } from '../../types';
import PageSection from '../ui/PageSection';
import Spacing from '../ui/Spacing';
import Text from '../ui/Text';
import IntegrationCard from './IntegrationCard';
import styles from './IntegrationsContent.module.scss';

interface IntegrationsContentProps {
  additional?: ReactNode;
  services: IntegrationService[];
  title: string | ReactNode;
}

const IntegrationsContent: React.SFC<IntegrationsContentProps> = ({
  additional,
  services,
  title,
}) => (
  <PageSection>
    <Spacing bottom={3}>
      {typeof title === 'string' ? (
        <Text large center bold>
          {title}
        </Text>
      ) : (
        title
      )}
    </Spacing>
    <div className={styles.grid}>
      {services.map((service) => (
        <IntegrationCard key={service.name} {...service} />
      ))}
      {additional}
    </div>
  </PageSection>
);

export default IntegrationsContent;
