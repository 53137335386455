import React from 'react';
import PageHeader from '../layout/PageHeader';
import PageSection from '../ui/PageSection';
import Spacing from '../ui/Spacing';

const IntegrationsHeader: React.SFC = () => (
  <Spacing bottom={10}>
    <PageSection>
      <PageHeader
        title="Integrations"
        description="Slab fits into your existing workflows and centralizes your team's knowledge"
      />
    </PageSection>
  </Spacing>
);

export default IntegrationsHeader;
