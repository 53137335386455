import { FileNode } from '../types';

interface FileMap {
  [name: string]: FileNode;
}

const toFileMap: (
  edges: { node: FileNode }[],
  key: keyof Omit<FileNode, 'childImageSharp'>,
) => FileMap = (edges, key) =>
  edges.reduce(
    (acc, { node }) => ({
      ...acc,
      [node[key]]: node,
    }),
    {},
  );

export default toFileMap;
