import { graphql } from 'gatsby';
import React from 'react';
import IntegrationRequestCard from '../../components/integrations/IntegrationRequestCard';
import IntegrationsContent from '../../components/integrations/IntegrationsContent';
import IntegrationsFilterHeader from '../../components/integrations/IntegrationsFilterHeader';
import IntegrationsHeader from '../../components/integrations/IntegrationsHeader';
import MarketingLayout from '../../components/layout/MarketingLayout';
import Spacing from '../../components/ui/Spacing';
import { FileNode, IntegrationMarkdownRemark } from '../../types';
import allIntegrations from '../../utils/allIntegrations';

interface FileConnect {
  edges: { node: FileNode }[];
}

type IntegrationsFilter = 'all' | 'premium' | 'standard';

export interface IntegrationsPageData {
  logos: FileConnect;
  icons: FileConnect;
  markdowns: {
    edges: { node: IntegrationMarkdownRemark }[];
  };
}

export interface IntegrationsPageProps {
  filter?: IntegrationsFilter;
  data: IntegrationsPageData;
}

const IntegrationsPage = ({ filter = 'all', data }: IntegrationsPageProps) => {
  const allServices = allIntegrations(data);
  const featuredServices = allServices.filter(({ featured: f }) => f).reverse(); // TODO: reverse alphabetical happens to be the desired order
  const listedServices =
    filter === 'all'
      ? allServices
      : allServices.filter(
          ({ premium }) => !!premium === (filter === 'premium'),
        );

  return (
    <MarketingLayout title="Integrations">
      <IntegrationsHeader />
      <IntegrationsContent services={featuredServices} title="Featured" />
      <Spacing top={12}>
        <IntegrationsContent
          services={listedServices}
          title={<IntegrationsFilterHeader />}
          additional={<IntegrationRequestCard />}
        />
      </Spacing>
    </MarketingLayout>
  );
};

export default IntegrationsPage;

export const query = graphql`
  fragment IntegrationsQueryFragment on Query {
    logos: allFile(
      filter: {
        name: { eq: "logo" }
        sourceInstanceName: { eq: "integrations" }
      }
    ) {
      edges {
        node {
          relativeDirectory
          publicURL
        }
      }
    }
    icons: allFile(
      filter: {
        name: { eq: "icon" }
        sourceInstanceName: { eq: "integrations" }
      }
    ) {
      edges {
        node {
          relativeDirectory
          publicURL
        }
      }
    }
    markdowns: allMarkdownRemark(
      filter: { fields: { type: { eq: "integrations" } } }
    ) {
      edges {
        node {
          html
          fields {
            slug
          }
          frontmatter {
            name
            description
            featured
            premium
          }
        }
      }
    }
  }

  query {
    ...IntegrationsQueryFragment
  }
`;
